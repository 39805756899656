import React from 'react'
import styled from 'styled-components'
import { Container } from '../components/containers/Container'
import Layout from '../components/layout'
import Seo from '../components/Seo'

const StyledContainer = styled(Container)`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin: 4rem auto;
  text-align: center;
  h1 {
    margin-bottom: 0;
  }
`
const ThankYouPage = ({ location }) => {
  return (
    <Layout>
      <Seo title="Thank You!" location={location.pathname} />
      <StyledContainer>
        <h1>Thank you for reaching out!!</h1>
        <p>A customer service representative will be in touch ASAP</p>
      </StyledContainer>
    </Layout>
  )
}

ThankYouPage.propTypes = {}

export default ThankYouPage